// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3oDfdtnqjiDTJEqfN-Vmd-FL0wtDe024",
  authDomain: "league-morning-star.firebaseapp.com",
  projectId: "league-morning-star",
  storageBucket: "league-morning-star.appspot.com",
  messagingSenderId: "462188696917",
  appId: "1:462188696917:web:d8f4d0e57fde7798dd4bdc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export const db = getFirestore(app);
