import React, { Component, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const auth = getAuth();

function togglePasswordVisibility () {
  const passwordInput = document.getElementById('passwordInput');
  const togglePassword = document.getElementById('togglePassword');

  if (passwordInput.type == 'password') {
    passwordInput.type = 'text';
    togglePassword.innerText = 'Hide';
  } else {
    passwordInput.type = 'password';
    togglePassword.innerText = 'Show';
  }
}

function createAccount () {
  // don't allow account creation yet...
  return;

  console.log('create account')
  const emailAddressInput = document.getElementById('emailAddressInput');
  const passwordInput = document.getElementById('passwordInput');

  const email = emailAddressInput.value;
  const password = passwordInput.value;

  console.log('signing in with')
  console.log('email:', email)
  console.log('password:', password)

  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // User created and signed in
    const user = userCredential.user;
    console.log('user created!');
    console.log(user);
    window.location.href = "/";
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    const emailInputGroup = document.getElementById('emailInputGroup');
    const passwordInputGroup = document.getElementById('passwordInputGroup');

    const emailInputFieldError = document.getElementById('emailInputFieldError');
    const passwordInputFieldError = document.getElementById('passwordInputFieldError');

    console.log('error')
    console.log(errorCode)
    console.log(errorMessage)

    if (errorCode == 'auth/weak-password') {
      passwordInputGroup.classList.add('input-group-error');
      passwordInputFieldError.style.display = 'block';
      passwordInputFieldError.innerText = 'Password should be at least 6 characters';
    } else {
      // email already in use: auth/email-already-in-use
      emailInputGroup.classList.add('input-group-error');
      emailInputFieldError.style.display = 'block';
      emailInputFieldError.innerText = 'Account creation failed';
    }
  });
}

function login () {
  console.log('login')
  const emailAddressInput = document.getElementById('emailAddressInput');
  const passwordInput = document.getElementById('passwordInput');

  const email = emailAddressInput.value;
  const password = passwordInput.value;

  // console.log('signing in with')
  // console.log('email:', email)
  // console.log('password:', password)

  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log('signed in!', user)
      window.location.href = "/account";
      })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // console.log('error code', error.code)

      // auth/invalid-email
      // auth/configuration-not-found
      // auth/wrong-password

      // console.log('error message', error.message)
    });

  const loginButton = document.getElementById('loginButton');
  if (loginButton.innerText == 'Create Account') {

  } else if (loginButton.innerText == 'Login') {

  }
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return (
      <div className="Login">
        <NavBarMini alwaysShown={true} />

        <div className="login-content">

          <div className="login-form">
            <div className="login-header">
              <h2 id="loginHeaderTitle">Log in to your account</h2>
            </div>
            <div className="login-body">
              <div id="emailField" className="login-field">
                <label>Email Address</label>
                <div id="emailInputGroup" className="login-input-group">
                  <input type="email" id="emailAddressInput"></input>
                </div>
                <label id="emailInputFieldError"></label>
              </div>
              <div id="passwordField" className="login-field">
                <label>Password</label>
                <div id="passwordInputGroup" className="login-input-group">
                  <input type="password" id="passwordInput"></input>
                  <button id="togglePassword" className="input-btn" type="button" onClick={togglePasswordVisibility}>Show</button>
                </div>
                <label id="passwordInputFieldError"></label>
              </div>
              <div id="forgotPasswordGroup" className="login-forgot-password">
                <p className="login-forgot-password-text">Forgot your password?</p>
              </div>
              <button id="loginButton" className="login-btn" type="submit" onClick={login}>Log In</button>
              {/* <button id="createButton" className="login-btn" type="submit" onClick={createAccount}>Create Account</button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
