import React, { Component, useRef, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';

import { renderHeaderTop }  from './ArticleUtils.js';

import { useParams } from "react-router-dom";

import { collection, query, where, getDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase';

const SouthieTimes = () => {

  if (false) {
    return (
      <div className="SouthieTimes">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="SouthieTimes">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <p className="league-title-standard">Southie Times</p>
            </div>
          </div>

          <div className="league-body">
            <div className="league-table-container schedule">
              Coming soon
            </div>
          </div>
        </div>


        <Footer/>
      </div>
    );
  }
}

export default SouthieTimes;
