import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { getAuth } from "firebase/auth";

function toggleNavButtons () {
	let navList = document.getElementById('nav-list')
	let navX = document.getElementById('nav-x')
	let navPrayer = document.getElementById('nav-prayer')
	let navAccount = document.getElementById('nav-account')
	if (navList.style.display !== 'none') {
		navList.style.display = 'none';
		navX.style.display = 'block';
		navPrayer.style.display = 'none';
		if (navAccount) {
			navAccount.style.display = 'none';
		}
	} else {
		navList.style.display = 'block';
		navX.style.display = 'none';
		navPrayer.style.display = 'block';
		if (navAccount) {
			navAccount.style.display = 'block';
		}
	}
}

function toggleNavMenu () {
		// toggle side nav menu
	let navMenuClassList = document.getElementById('nav-menu').classList
	if (navMenuClassList.contains('nav-open')) {
		navMenuClassList.toggle('nav-open')
		navMenuClassList.toggle('nav-closed')
	} else if (navMenuClassList.contains('nav-closed')) {
		navMenuClassList.toggle('nav-closed')
		navMenuClassList.toggle('nav-open')
	} else {
		navMenuClassList.toggle('nav-open')
	}

	// make body non-scrollabsle
	if (document.body.style.overflow) {
		document.body.style.overflow = '';
	} else {
		document.body.style.overflow = 'hidden';
	}

	// toggle list icon and 'X' icon
	toggleNavButtons();
}

function redirectToPrayerIntentionsPage () {
	window.location.href = `/prayer-intentions/new`
}

function redirectToAccountPage () {
	window.location.href = `/account`
}

const NavBarMini = (props) => {
	let alwaysShown = props.alwaysShown;
	let page = props.page;

	const auth = getAuth();
  const currentUser = auth.currentUser

  return (
    <Col className={`NavBarMini ${alwaysShown ? "always-shown" : ""}`}>
      <Row className="nav-top-mini">

      </Row>

      <Row className="nav-header-mini">
        <Col id="main-nav-left">
					<button id="nav-list" onClick={toggleNavMenu} type="button">
						<svg id="nav-list-svg" viewBox="0 0 16 16"><rect x="1" y="3" fill="#333" width="14" height="2"></rect><rect x="1" y="7" fill="#333" width="14" height="2"></rect><rect x="1" y="11" fill="#333" width="14" height="2"></rect></svg>
					</button>
					<span id='nav-title'>{ page }</span>
        </Col>

        <Col xs={5} sm={6} id="main-logo-mini">
					<svg viewBox="0 0 192 192" className={ page == 'Account' ? '' : 'hidden' }>
						<g id="nameLogoSmall">
							<a href="/">
								<path d="M148.5,27.8l5.9-5.9l1.5,2.1l-22.1,27.6c-7.8-3.8-15.5-8.3-22.5-13.1c0,39.8,0,79.4,0,119.2c17.3-2.9,30-16.4,36.3-31.7h3.6c-1.4,33.2-23.4,57.2-52.1,57.2c-35.3-0.2-62.9-38.3-62.9-80.6c0-41.4,23.6-72.8,50.3-83.6l1.8,3.2c-8.1,5.1-14.9,12.6-19.7,22.2l48.6-35.7C126.4,17,136.6,23.3,148.5,27.8z M73.9,118.9c0-23.6,0-47.3,0-70.8l-9.8,7.2c-3.5,10.4-5.3,22.5-5.3,35.9c0,12,1.7,23.3,4.8,33L73.9,118.9z M104.9,158.2c0.3,0,0.8,0,1.1,0c0-41.1,0-82.2,0-123.4c-2.3-1.7-4.7-3.5-6.8-5.3l-1.5,1.1v82.8l-32,15.9C73,146.8,86,158.2,104.9,158.2z"/>
							</a>
						</g>
					</svg>
					<svg version="1.1" viewBox="0 0 1500 192.6" className={ page == 'Account' ? 'hidden' : '' }>
						<style type="text/css">
						</style>
						<g id="nameLogo">
							<a href="/">
								<path className="st0" d="M6,100.5H3C1.6,96.9,0.9,92.9,0.9,89c0-9.5,3.8-16.2,9.4-19.1c2.2-1.2,4.4-1.9,9.5-1.9v-2.7
									c0-19.9,7.8-21.4,20-32.1c-2.1,0.6-4.4,1-6.7,1c-6.5,0-12.3-2.8-18.5-2.8c-5.3,0-9.3,3-10.8,7.7H0c0.8-2,10.1-27.8,27.5-27.8
									c7.7,0,13.7,4.7,21.4,4.7c5.8,0,10.5-2.7,13.7-6.3l-0.2,5.1C61.6,15.9,57,22.7,50,27.9c-0.7,0.7-1.4,1.5-2.1,2.2
									c-5.3,5.9-7.1,17-7.1,27.9l0,38.4c0,21.4-13.6,41.5-25.5,41.5H9.9c6.6-5,9.9-18,9.9-36.5V89.3c-1.6,0-3.1,0-4.7,0
									C8.6,89.3,5,93.8,6,100.5z M57.8,126.8c15.6,0,29.2,5.6,40.3,12.3l5.3-4.6l2.6,4.4l-23.6,21.2c-10.5-7.3-22.6-11.3-34.8-11.3
									c-15.3,0-29.2,5.8-40.9,16.2v-3.6c6.9-14.9,18.6-27,34.5-32.1c5.2-7,6.7-18.3,6.7-35.2V65.2c0-37.4,12.3-49.7,37.7-55.2l1.8-0.4
									v4.3l-2.7,1c-12.3,4.3-15.9,17.1-16,33.6l0,44.7c0,15.2-4.3,21.8-17.6,34C53.1,126.9,55.4,126.8,57.8,126.8z"/>
								<path className="st0" d="M108.7,139.7l7.6-7.4V81.4L155,57.3l16.2,29.7v0.9l-34.8,25.9v8.3l19.3,15.8l17.8-16.7l2.3,4.6l-33.2,32.4
									L118.7,137l-7.1,6.9L108.7,139.7z M152.6,94.8l-13.1-22.7l-3.1,2.1v33L152.6,94.8z"/>
								<path className="st0" d="M182.5,131.9c0-10.3,6.6-20.2,18.6-27.8c-7.4-3.8-13.1-9.4-13.1-18.4c0-8.5,5.3-13.4,10.1-16.3l17.8-11.1
									l23.8,18.4l6.5-6.3l2.8,4.2l-8.4,8.2v48.7l7.7,9.3l7-5.6l0.8,3.7l-22.4,18.5l-12.3-14.8l-17,14.8
									C194,155.8,182.5,146.3,182.5,131.9z M192.6,84.5c0,12.4,17,17.5,27.8,20.5c0-6,0-12.1,0-18.1l-19-14.1
									C196.7,75.9,192.6,79.5,192.6,84.5z M204,105.4c-1.1,1.9-2,5.2-2,8.9c0,11,5.1,26.4,15.1,26.4c1.1,0,2.3-0.2,3.3-0.6
									c0-9.7,0-19.5,0-29.3C215.3,109.4,209.4,107.8,204,105.4z"/>
								<path className="st0" d="M307.1,164.5c0-7.5-4.2-9.9-5.6-20.5l-15,12.4L263.3,137V81.4l36.3-25l21.2,19l6.8-6.7l2.8,4.2l-7.7,7.5
									c0,16.4,0,32.8,0,49.1c0,16,4.6,15.8,4.6,23c0,8.4-6.9,10.5-11.1,14l-29.8,25.2c-3.4-6.2-8.9-10.6-16.5-10.6
									c-4.3,0-8.7,1.7-12.2,4.8l-2.3-1.9c3.6-11.4,13.1-21.3,25.6-21.3c7.4,0,14.8,3.8,19.9,10.2C304.7,172.6,307.1,169.1,307.1,164.5z
									M301,136.6l0.1-47.7L282.6,74v50.9l18.5,13.8C301.1,138.1,301,137.3,301,136.6z"/>
								<path className="st0" d="M336,76.9l-1.6-2.8l20.8-16.1c7.6,3.4,12.7,10.6,12.7,23.2v46.5l10.1,11.5l7.6-6.8l0-9.4V83.4l-8.4-8.9
									l15.1-16.7l10.6,11l5.2-5.1l2.8,4.2l-7,6.8v45.9l0.1,8c0.1,5.9,1.2,10.6,4.3,13.2l6-5.9l3,4.8l-19.3,15.9
									c-5.9-0.3-11.2-6.5-12.4-18.8l-20.3,19.2l-22-22l5.1-5l0-45.4c0,0,0,0,0-0.1c0-5.9-1.6-10.9-6.4-10.9
									C339.5,73.7,337.5,75.4,336,76.9z"/>
								<path className="st0" d="M418.9,139.7l7.6-7.4V81.4l38.6-24.1l16.2,29.7v0.9l-34.8,25.9v8.3l19.3,15.8l17.8-16.7l2.3,4.6l-33.2,32.4
									L428.9,137l-7.1,6.9L418.9,139.7z M462.8,94.8l-13.1-22.7l-3.1,2.1v33L462.8,94.8z"/>
								<path className="st0" d="M587,9.7l3.1,1.9c-11.8,15.8-17.7,19.4-25.3,19.4c-6.1,0-10.3-3.8-16.2-3.8c-5.3,0-8.8,3.9-10.2,8.5l-3.1-1.9
									c4.5-17,14.6-24.2,22-24.2c8.1,0,13.1,5.7,21.6,5.7C582.8,15.3,584.9,12.2,587,9.7z M550.7,89.7c1.8,0,3.5,0,5.3,0v14.6
									c0,8.3-0.6,17-7.6,17.9l0.8,3.7c13-1.1,27.7-8.2,27.7-27.4V55.3c0-11.1,3.9-17.9,9.3-22.7c0,23.5,0,47,0,70.5
									c0,17.9-3.7,27.7-26.6,27.7c-10.8,0-19,9.1-19,22.7c0,11.7,7.2,22.5,17.3,22.5c0.7,0,1.3-0.1,2-0.2v-4.6c-0.2,0-0.3,0-0.5,0
									c-4.1,0-8.1-2.9-8.1-7.8c0-7.4,7.3-8.3,13-8.8c14.5-1.3,26.5-9.2,26.5-28.2c0-0.1,0-0.1,0-0.1l0-18.6v-7.4c5.1-4.5,12.3-4,17,1.2
									c0,10,0,19.9,0,29.9l-10,8.3l1.5,2.8l5.4-4.6l18.5,18.5l27-24.1l-2.3-2.8l-5.1,4.1c0-11.1,0-22.2,0-33.3c5-4.3,12-4,16.7,0.9v30.8
									l-9.3,7.7l1.5,2.8l5.4-4.6l18.5,18.5l24.6-24.1l-2.3-2.8l-9.1,7.3l-7-7.3V39.6l8.4-8.2l-2.8-4.2l-6.7,6.5l-20.4-21.9l-17.5,12.6
									c0-3.6,0-7.2,0-10.8h-4.6c0,4.7,0,9.4,0,14.1l-8.8,6.3c-7.5-7.4-15-14.9-22.4-22.3l-32.3,24.5c-12.9,9.8-17.5,18.2-18.6,30.9
									c-13.4,1.9-20.3,10.5-20.3,22.2c0,4,0.9,7.9,2.5,11.4h3.1C540.3,93.6,544.6,89.7,550.7,89.7z M590.8,72V33.1l17,14
									c0,7.9,0,15.8,0,23.7C603.1,76,595.9,76.4,590.8,72z M590.8,77.6c5.2,4.4,12.3,3.9,17-1.1c0,6.5,0,13,0,19.4
									c-5-5.3-12.1-5.2-17-1.1V77.6z M638.3,136.3l-9.6-8.6l0-87.5l9.6-7C638.3,67.6,638.3,102,638.3,136.3z M642.9,72
									c0-13.4,0-26.7,0-40.1L659.6,48v23.1C654.9,76,647.9,76.3,642.9,72z M642.9,77.6c5.1,4.2,12,3.9,16.7-0.8v18.8
									c-5-5-11.8-4.9-16.7-0.8C642.9,89,642.9,83.3,642.9,77.6z"/>
								<path className="st0" d="M704.1,139.1l8.2-8c0-16.6,0-33.1,0-49.7l36.3-25l23.2,21.3v52.8l-35.5,25.9l-22.7-19.6l-6.7,6.5L704.1,139.1z
									M751.7,141.6V86l-18.5-14.8v55.6L751.7,141.6z"/>
								<path className="st0" d="M832.2,136.5l-24.1,20.9l-17.7-16.7l-5.4,4.6l-2.3-3.7l10-8.3v-51l-5.4-8.3l-6.2,4.6l-2.3-3.7l21.6-17.6
									l11.8,15.2l11.2-15.3h1.7c1.5,2.9,4.3,4.8,7.3,4.8c3.3,0,5.9-2.2,7.3-4.8h4.8c-1.9,24-11.2,27-16,27c-3.5,0-9.2-1.9-14.4-8v57
									l6.4,6l8.7-7.1L832.2,136.5z"/>
								<path className="st0" d="M932.3,139.7l-23.9,19.5l-16.2-17.6l5.4-6.5V87.5l-11.6-12l-7,6.6v53.1l7,7.4l-15.4,16.7L852,141.6l6.2-6.5
									V83.8l-6.2-8.3l-6.2,4.6l-2.3-3.7l22.4-18.5l12.4,16.6L896,57.8l18.3,18.8L921,70l2.8,4.2l-7.8,7.6v50.5l7,7.4l6.2-5.6L932.3,139.7
									z"/>
								<path className="st0" d="M984.5,137.9l-23.2,19.5l-15.5-16.7l-5.4,4.6l-2.3-3.7l10-8.3v-51l-5.4-8.3l-6.2,4.6l-2.3-3.7L956,57.3
									l12.4,17.6v58.4l5.4,6.5l6.9-5.6L984.5,137.9z M958.5,15l13.7,18.8L956.8,50l-13.7-18.8L958.5,15z"/>
								<path className="st0" d="M1071.8,139.7l-23.9,19.5l-16.2-17.6l5.4-6.5V87.5l-11.6-12l-7,6.6v53.1l7,7.4l-15.4,16.7l-18.5-17.6l6.2-6.5
									V83.8l-6.2-8.3l-6.2,4.6l-2.3-3.7l22.4-18.5l12.4,16.6l17.7-16.6l18.3,18.8l6.8-6.6l2.8,4.2l-7.8,7.6v50.5l7,7.4l6.2-5.6
									L1071.8,139.7z"/>
								<path className="st0" d="M1123.8,164.5c0-7.5-4.2-9.9-5.6-20.5l-15,12.4L1080,137V81.4l36.3-25l21.2,19l6.8-6.7l2.8,4.2l-7.7,7.5
									c0,16.4,0,32.8,0,49.1c0,16,4.6,15.8,4.6,23c0,8.4-6.9,10.5-11.1,14l-29.8,25.2c-3.4-6.2-8.9-10.6-16.5-10.6
									c-4.3,0-8.7,1.7-12.2,4.8l-2.3-1.9c3.6-11.4,13.1-21.3,25.6-21.3c7.4,0,14.8,3.8,19.9,10.2C1121.4,172.6,1123.8,169.1,1123.8,164.5
									z M1117.8,136.6l0.1-47.7L1099.4,74v50.9l18.5,13.8C1117.8,138.1,1117.8,137.3,1117.8,136.6z"/>
								<path className="st0" d="M1250.6,8c6.1,6.8,14.9,12.4,24.3,12.4c9.8,0,18.5-7.1,19.3-18.8h0c0,0,0,0,0,0l2.3,0h0.5v0l1.7,0l0,2.7
									c-0.1,25-14.4,41.5-27,55c9.1,3.6,17.2,9.3,23.9,15.9v48.5l-44.2,33.4c-6.7-8.9-16.7-16.4-28.8-16.4c-9,0-21.1,2.5-19.6,16.5
									c3,17.3,21.6,20.7,35.6,20.7c16.2,0,36.9-6.9,41.7-25.3l4.3,1.5c-4.8,22.5-25.3,38.5-46.5,38.5c-21.1,0-40-15.7-40-39.7
									c0-22,17.7-41.1,29.2-53.4l1.5-1.6l-22.5-17.7V41.4L1250.6,8z M1260.7,80.6l-5.8-3.4l-24.5,26.3c-5.4,5.8-12.7,13.6-18.5,22.5
									c6-5.2,13.6-8.2,22.2-8.2c10.2,0,19,3.5,26.6,8.5V80.6z M1230.5,30.2l-3.1,2.5v33.2l8.9,7.4V34.3
									C1234.3,33,1232.4,31.6,1230.5,30.2z M1240.9,77l4.2,3.5l19.8-21.3c5.8-6.2,12.6-13.3,18.2-21.6c-5.4,4-12,6.2-19,6.2
									c-8.8,0-16.5-2.9-23.3-6.8V77z M1271.5,134.9l3.1-1.7V88.7l-9.3-5.5v46.2C1267.5,131.2,1269.6,133.1,1271.5,134.9z"/>
								<path className="st0" d="M1342.3,142.6l5.3-3.5l2.3,3.7l-22.2,13.8l-17-17.6l-6.2,4.6l-2.3-3.7l10.8-8.3V85.2c0-7.5-4.5-9.2-7.7-9.2
									v-2.8l26.3-31.6h3.1v22.2h19.2l-7.6,10.6h-11.6v59L1342.3,142.6z"/>
								<path className="st0" d="M1360.4,131.9c0-10.3,6.6-20.2,18.6-27.8c-7.4-3.8-13.1-9.4-13.1-18.4c0-8.5,5.3-13.4,10.1-16.3l17.8-11.1
									l23.8,18.4l6.5-6.3l2.8,4.2l-8.4,8.2v48.7l7.7,9.3l7-5.6l0.8,3.7l-22.4,18.5l-12.3-14.8l-17,14.8
									C1371.9,155.8,1360.4,146.3,1360.4,131.9z M1370.6,84.5c0,12.4,17,17.5,27.8,20.5c0-6,0-12.1,0-18.1l-19-14.1
									C1374.6,75.9,1370.6,79.5,1370.6,84.5z M1382,105.4c-1.1,1.9-2,5.2-2,8.9c0,11,5.1,26.4,15.1,26.4c1.1,0,2.3-0.2,3.3-0.6
									c0-9.7,0-19.5,0-29.3C1393.2,109.4,1387.3,107.8,1382,105.4z"/>
								<path className="st0" d="M1487.6,136.5l-24.1,20.9l-17.7-16.7l-5.4,4.6l-2.3-3.7l10-8.3v-51l-5.4-8.3l-6.2,4.6l-2.3-3.7l21.6-17.6
									l11.8,15.2l11.2-15.3h1.7c1.5,2.9,4.3,4.8,7.3,4.8c3.3,0,5.9-2.2,7.3-4.8h4.8c-1.9,24-11.2,27-16,27c-3.5,0-9.2-1.9-14.4-8v57
									l6.4,6l8.7-7.1L1487.6,136.5z"/>
								<rect className="transparent" width="1500" height="192.6"/>
							</a>
						</g>
					</svg>
        </Col>
        <Col id="main-nav-right">
					{ currentUser ?
						<a id="nav-account" onClick={redirectToAccountPage} type="button">👨🏼‍💻</a> : ''
					}
					<a id="nav-prayer" onClick={redirectToPrayerIntentionsPage} type="button">🙏</a>
					<button id="nav-x" onClick={toggleNavMenu} type="button">
						<svg id="nav-x-svg" viewBox="0 0 12 12" stroke="black" strokeWidth="1" strokeLinecap="round"><line x1="11" y1="1" x2="1" y2="11"></line><line x1="1" y1="1" x2="11" y2="11"></line></svg>
					</button>
				</Col>
      </Row>

			<div id="nav-menu">
				<Row className="nav-links-row-mini">
					<ul className="nav-links-mini">
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/">Home</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/teams">Teams</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/schedule">Schedule</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/prayer-intentions/new">Prayer Intention</a></li>
						<li className="centered-nav-item nav-text"><a className="nav-link-mini" href="/southietimes">Southie Times</a></li>
					</ul>
				</Row>
			</div>

    </Col>
  );
}

export default NavBarMini
