import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SHOW_BLOCKS_AND_STEALS = true;
const playoffRoundTagNames = ['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']
const playoffRoundNames = {
  'playin': 'Play-In',
  'firstround': 'First Round',
  'quarterfinals': 'Quarterfinals',
  'semifinals': 'Semifinals',
  'championship': 'Championship',
}

// Used for registering swipe actions:
let startOfSwipeX = 0;
let endOfSwipeX = 0;
let startOfSwipeY = 0;
let endOfSwipeY = 0;

const Seasons = () => {
  const { seasonID } = useParams();

  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: [],
    seasonTeams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  function getTeamForID(id) {
    return league.teams.find((team) => team.id == id);
  }

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let teams = league.seasonTeams.filter((team) => team.season == seasonID)
    let updatedTeams = []
    for (const t of teams) {
      let teamData = league.teams.find((tm) => tm.id == t.team_id)
      let team = { ...teamData, ...t }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    league.games = league.games.filter((game) => game.season == seasonID)

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });

    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function getCompletedGames () {
    return league.games.filter((game) => game.status == 'completed' && game.type == 'regular_season')
  }

  function getStatsForTeams () {
    let teamData = {};
    league.teams.forEach((team) => teamData[team.id] = {
      wins: 0,
      losses: 0,
      games: 0,
      streak: 0,
      pointsAgainst: 0,
      pointsFor: 0
    })

    let completedGames = getCompletedGames();

    let sortedCompletedGames = completedGames.sort((a, b) => (a.date < b.date) ? -1 : 1)
    sortedCompletedGames.forEach((game) => {
      let winner = game.winner;
      let loser = game.winner == game.away_team_id ? game.home_team_id : game.away_team_id;

      let winnerScore = game.score.away > game.score.home ? game.score.away : game.score.home;
      let loserScore = game.score.away > game.score.home ? game.score.home : game.score.away;

      teamData[winner].wins += 1;
      teamData[winner].games += 1;
      teamData[winner].pointsAgainst += loserScore;
      teamData[winner].pointsFor += winnerScore;
      if (teamData[winner].streak < 0) {
        teamData[winner].streak = 1;
      } else {
        teamData[winner].streak += 1;
      }

      teamData[loser].losses += 1;
      teamData[loser].games += 1;
      teamData[loser].pointsAgainst += winnerScore;
      teamData[loser].pointsFor += loserScore;
      if (teamData[loser].streak > 0) {
        teamData[loser].streak = -1;
      } else {
        teamData[loser].streak -= 1;
      }
    })

    let defaultStats =  {
      pointsFor: '0',
      pointsAgainst: '0',
      streak: '-',
      wins: '0',
      losses: '0',
      games: '0',
      winPercentage: '.000',
      winPercentageValue: 0
    };

    let newTeamList = [];
    league.teams.forEach((team) => {
      let t = teamData[team.id];
      if (t.wins === t.games && t.games > 0) {
        t.winPercentage = '1.000';
        t.winPercentageValue = 1;
      } else if(t.wins + t.losses !== 0){
        t.winPercentage = (t.wins / t.games).toFixed(3).toString().slice(1);
        t.winPercentageValue = t.wins / t.games
      }

      let existingTeamData = team;
      let stats = {...defaultStats, ...teamData[team.id]};
      existingTeamData.stats = stats;

      newTeamList.push(existingTeamData);
    })

    league.teams = newTeamList;
  }

  function printStreak (streak) {
    if (streak === 0) {
      return '-';
    }
    else if (streak < 0) {
      return Math.abs(streak).toString() + 'L'
    } else {
      return streak.toString() + 'W'
    }
  }

  function displayTeam(team, place) {
    let stats = team.stats;

    return (
        <tr id={team.id + "-team-row"}>
          <td className="league-table-data-name user-name no-wrap"><span>{ place + '. ' }</span><a className="unstyled-link" href={"/teams/" + team.id }>{ team.name.toUpperCase() }</a></td>
          <td className="league-table-data desktop-only"><span>{ stats.wins }</span></td>
          <td className="league-table-data desktop-only"><span>{ stats.losses }</span></td>
          <td className="league-table-data wide-fixed mobile-only"><span>{ stats.wins }-{ stats.losses }</span></td>
          <td className="league-table-data wide"><span>{ stats.winPercentage }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsFor }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsAgainst }</span></td>
          <td className="league-table-data wide desktop-only"><span>{ stats.pointsFor - stats.pointsAgainst }</span></td>
          <td className="league-table-data wide-fixed desktop-only"><span>{ printStreak(stats.streak) }</span></td>
        </tr>
    );
  }

  function headToHeadValue (a,b) {
    let gamesAgainstEachother = league.games.filter((game) => game.status == 'completed' && game.type == 'regular_season' && ((game.away_team_id == a.id && game.home_team_id == b.id) || (game.away_team_id == b.id && game.home_team_id == a.id)))
    let gamesTeamAWon = gamesAgainstEachother.filter((game) => game.winner == a.id)

    if (gamesAgainstEachother.length == 0) {
      return 0;
    } else if (gamesTeamAWon.length / gamesAgainstEachother.length < .5) {
      return 1;
    } else if (gamesTeamAWon.length / gamesAgainstEachother.length > .5) {
      return -1;
    } else {
      return 0;
    }
  }

  function rankTeams () {
    getStatsForTeams();

    let teams_sorted = []

    if (seasonID < SEASON) {
      teams_sorted = league.teams.sort((a, b) => a.seed - b.seed);
    } else {
      // Ranking by Win Percentage
      teams_sorted = league.teams.sort((a, b) => {
        if (a.stats.winPercentageValue > b.stats.winPercentageValue) {
          return -1;
        } else if (a.stats.winPercentageValue < b.stats.winPercentageValue) {
          return 1;
        } else if (a.stats.losses > b.stats.losses) { // 0-1 teams > 0-2 teams
          return 1;
        } else if (a.stats.losses < b.stats.losses) { // "                   "
          return -1;
        } else if (a.stats.games > b.stats.games) { // 2-0 teams > 1-0 teams
          return -1;
        } else if (a.stats.games < b.stats.games) { // "                   "
          return 1;
        } else {
          // Tiebreaker #1 - Head to Head
          let headToHead = headToHeadValue(a,b);
          if (headToHead !== 0) {
            return headToHead;
          } else {
            // Tiebreaker #2 - Point Differential +/-
            let pointDifferentialTeamA = a.stats.pointsFor - a.stats.pointsAgainst;
            let pointDifferentialTeamB = b.stats.pointsFor - b.stats.pointsAgainst;
            if (pointDifferentialTeamA > pointDifferentialTeamB) {
              return -1;
            } else if (pointDifferentialTeamA > pointDifferentialTeamB) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      })
    }

    league.teams = league.teams
    return teams_sorted;
  }

  function displayTeams () {
    if (!league.teams){
      return;
    }
    let sorted_teams = rankTeams();

    let team_elements = sorted_teams.map((team, place) => displayTeam(team, place+1) )

    return team_elements;
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`
    let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    return (
      <div className="league-game-container-homepage"  onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <p className="league-title-skinny-small" key={gameID + "-date"}>{ game.date.toDateString() } @ { hours }:{ game.date.getMinutes() } { hours_label }</p>
        <p key={gameID}>{ away_team.name.toUpperCase() } vs. { home_team.name.toUpperCase() }</p>
      </div>
    )
  }

  function anyGamesInLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    return gamesInTheLastDay.length > 0;
  }

  function displayFinishedGamesLastDay () {
    let today = Date.now();
    let oneDayAgo = today - (1 * 24 * 60 * 60 * 1000);

    let gamesInTheLastDay = league.games.filter((game) => game.date < today && game.date > oneDayAgo && game.status == 'completed')
    let gameElements = gamesInTheLastDay.map((game) => displayFinishedGameThisWeek(game) )
    return gameElements;
  }

  function displayGamesThisWeek () {
    let today = Date.now();
    let sevenDays = today + (7 * 24 * 60 * 60 * 1000);

    let gamesThisWeek = league.games.filter((game) => game.date > today && game.date < sevenDays && game.status !== 'completed')
    let finishedGames = league.games.filter((game) => game.date <= today || game.status === 'completed' )

    if (league.games == 0) {
      return <p>Waiting for games to be added to website...</p>
    }
    if (gamesThisWeek.length == 0 && finishedGames.length < 40) {
      return <p>No upcoming games in the next week</p>
    }
    let sortedGamesThisWeek = gamesThisWeek.sort((a, b) => (a.date < b.date) ? -1 : 1)

    let gameElements = sortedGamesThisWeek.map((game, place) => displayGameThisWeek(game) )
    return gameElements;
  }

  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }

  function displayFinishedGameThisWeek (game) {
    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }
    return (
      <div className='league-game-container' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-scores'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
          <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
        </div>
      </div>
    )
  }

  function displayPlayoffGame (game) {
    let gameTBD = false;

    let away_team;
    let home_team;
    if (game.away_team_id) {
      away_team = getTeamForID(game.away_team_id);
    } else {
      const placeholderName = game.away_team_placeholder
      away_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    if (game.home_team_id) {
      home_team = getTeamForID(game.home_team_id);
    } else {
      const placeholderName = game.home_team_placeholder
      home_team = {
        seed: '',
        name: placeholderName || 'TBD'
      };
      gameTBD = true;
    }

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }

    let today = Date.now();

    if (game.status !== 'completed' && game.date > today) {
      return (
        <div className='league-game-container' onClick={() => {
          window.location.href = `/games/${game.id}`
        }} key={game.id + '-game-container'}>
          <div className='league-game-team-names-and-seeds'>
            <div className='league-game-team-seeds'>
              <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
              <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
            </div>
            <div className='league-game-team-names'>
              <p className={ game.winner === game.away_team_id && game.winner !== '' ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away-name'}>{ away_team.name.toUpperCase() }</p>
              <p className={ game.winner === game.home_team_id && game.winner !== '' ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home-name'}>{ home_team.name.toUpperCase() }</p>
            </div>
          </div>
          <div className='league-game-time-and-stats'>
            <p className='league-game-time'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
            <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
            <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
          </div>
        </div>
      )
    } else if (game.date <= today || game.status === 'completed') {
      return (
        <div className='league-game-container' onClick={() => {
          window.location.href = `/games/${game.id}`
        }} key={game.id + '-game-container'}>
          <div className='league-game-team-names-and-seeds-and-scores'>
            <div className='league-game-team-names-and-seeds'>
              <div className='league-game-team-seeds'>
                <p className='league-game-team-seed' key={game.id + '-away-seed'}>{ away_team.seed }</p>
                <p className='league-game-team-seed' key={game.id + '-home-seed'}>{ home_team.seed }</p>
              </div>
              <div className='league-game-team-names'>
                <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
                <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
              </div>
            </div>
            <div className='league-game-team-scores-with-seeds'>
              <p className={ game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
              <p className={ game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
            </div>
          </div>
          <div className='league-game-time-and-stats'>
            <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
            <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          </div>
        </div>
      )
    }
  }

  function displayPlayoffGames (round) {
    // let games = []
    // let rankedTeams = league.teams
    // let totalTeams = rankedTeams.length

    // let roundNameMap = {
    //   '4': 'quarterfinal',
    //   '2': 'semifinal',
    //   '1': 'championship'
    // }
    // let roundName = roundNameMap[numberOfGames.toString()]

    let gamesThisRound = league.games.filter((game) => game.type == 'playoff' && game.playoff_tags && game.playoff_tags.includes(round));

    let sortedGames = gamesThisRound.sort(function(a,b) {
      let orderA = a.playoff_round_order || 0;
      let orderB = b.playoff_round_order || 0;
      if (orderA < orderB) {
        return -1;
      } else if (orderB < orderA) {
        return 1;
      } else {
        return 0;
      }
    })

    // let gamesThisRoundWithTeams = gamesThisRound.filter((game) => game.away_team_id !== '' && game.home_team_id !== '')
    // let gamesThisRoundWithoutTeams = gamesThisRound.filter((game) => game.away_team_id == '' || game.home_team_id == '')
    let gameElements = sortedGames.map((game, place) => displayPlayoffGame(game))
    return (
      <>
        {gameElements}
      </>
    );
  }

  function displayPlayoffsButtons () {
    let playoffButtons = [];

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds
    for (let i = playoffRounds; i < playoffRoundTagNames.length; i++) {
      let playoffRoundTagName = playoffRoundTagNames[i];
      let playoffRoundName = playoffRoundNames[playoffRoundTagName];

      let buttonClass = '';
      if (SEASON === seasonID) {
        const CURRENT_ROUND = utils.getCurrentPlayoffRound();
        if (playoffRoundTagName == CURRENT_ROUND) {
          buttonClass = 'btn-dark';
        }
      } else {
        if (playoffRoundTagName == 'championship') {
          buttonClass = 'btn-dark';
        }
      }

      let roundClassName = "round_" + (i + 1 - playoffRounds) + "_of_" + numberPlayoffRounds

      playoffButtons.push(
        <>
          <div className='league-bracket-round-button'>
            <a id={ playoffRoundTagName + "Button" } type="button" className={ "btn " + buttonClass } onClick={ () => { clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds) } }>{ playoffRoundName }</a>
          </div>
        </>
      )
    }

    return (
      <div className='league-bracket-round-buttons scroll-on-overflow'>
        { playoffButtons }
      </div>
    )
  }

  function clickPlayoffRoundButton(playoffRoundTagName, roundClassName, playoffRounds){
    let leagueBracket = document.getElementsByClassName('league-bracket')[0];
    leagueBracket.classList = '';
    leagueBracket.classList.add('league-bracket');
    leagueBracket.classList.add(roundClassName);

    let button = document.getElementById(playoffRoundTagName + 'Button');
    button.classList.add('btn-dark')

    for (let j = playoffRounds; j < playoffRoundTagNames.length; j++) {
      let playoffOtherRoundTagName = playoffRoundTagNames[j];
      if (playoffRoundTagName == playoffOtherRoundTagName) {
        continue;
      }

      let playoffRoundButton = document.getElementById(playoffOtherRoundTagName + 'Button');
      playoffRoundButton.classList.remove('btn-dark')
    }
  }

  function displayPlayoffs () {
    return (
      <>
        { displayPlayoffRounds() }
      </>
    );
  }

  function swipePlayoffRound (swipeDirection, rounds) {
    let playinButton = document.getElementById('playinButton');
    let firstroundButton = document.getElementById('firstroundButton');
    let quarterfinalsButton = document.getElementById('quarterfinalsButton');
    let semifinalsButton = document.getElementById('semifinalsButton');
    let championshipButton = document.getElementById('championshipButton');

    let roundButtons = [playinButton, firstroundButton, quarterfinalsButton, semifinalsButton, championshipButton]

    for (let i = 0; i < roundButtons.length; i++) {
      let roundButton = roundButtons[i]
      if (roundButton.classList.contains('btn-dark')){
        if (swipeDirection == 'right' && i >= roundButtons.length - 1) {
        } else if (swipeDirection == 'left' && i <= roundButtons.length - rounds) {
        } else if (swipeDirection == 'left') {
          roundButtons[i-1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i-1].click();
        } else if (swipeDirection == 'right') {
          roundButtons[i+1].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
          roundButtons[i+1].click();
        }
        break;
      }
    }
  }

  function checkDirectionOfSwipe (rounds) {
    if (Math.abs(startOfSwipeX - endOfSwipeX) < 10) {
      return;
    }
    if (Math.abs(startOfSwipeX - endOfSwipeX) < Math.abs(startOfSwipeY - endOfSwipeY)) {
      return;
    }

    if (endOfSwipeX < startOfSwipeX) {
      swipePlayoffRound('right', rounds)
    } else if (endOfSwipeX > startOfSwipeX) {
      swipePlayoffRound('left', rounds)
    }
  }

  function displayPlayoffRounds () {
    let divClass = '';
    let gamesToChampionshipMap = {
      'playin': '4',
      'firstround': '3',
      'quarterfinals': '2',
      'semifinals': '1',
      'championship': '0'
    }

    const playoffRounds = playoffRoundTagNames.length - (league.teams.length / 2) + 1
    const numberPlayoffRounds = playoffRoundTagNames.length - playoffRounds

    let currentRound = ''
    if (SEASON === seasonID) {
      currentRound = utils.getCurrentPlayoffRound();
    } else {
      currentRound = 'championship'
    }
    let currentRoundIndex = numberPlayoffRounds - gamesToChampionshipMap[currentRound]

    let roundClassName = "round_" + currentRoundIndex + "_of_" + numberPlayoffRounds

    if (seasonID == 1){
      return (
        <div className={ "league-bracket " + roundClassName } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 3
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    } if (seasonID == 2){
      return (
        <div className={ "league-bracket " + roundClassName } onTouchStart={(e) => {
          startOfSwipeX = e.changedTouches[0].screenX;
          startOfSwipeY = e.changedTouches[0].screenY;
        }} onTouchEnd={(e) => {
          endOfSwipeX = e.changedTouches[0].screenX;
          endOfSwipeY = e.changedTouches[0].screenY;
          let rounds = 5
          checkDirectionOfSwipe(rounds)
        }}>
          { displayPlayoffsForRounds(['playin', 'firstround', 'quarterfinals', 'semifinals', 'championship']) }
        </div>
      );
    }
  }

  function displayPlayoffsForRounds(rounds) {
    let playoffBracket = rounds.map((round) => displayPlayoffRound(round))

    return (
      <>
        { playoffBracket }
      </>
    );
  }

  function displayPlayoffRound(round) {
    let roundNameMap = {
      'playin': 'Play-In',
      'firstround': 'First Round',
      'quarterfinals': 'Quarterfinals',
      'semifinals': 'Semifinals',
      'championship': 'Championship'
    }
    const roundName = roundNameMap[round]

    let roundLocation = 'South Boston Catholic Academy'

    if (seasonID == 1) {
      let roundLocationMap = {
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'Joseph P. Tynan School',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    } else if (seasonID == 2) {
      let roundLocationMap = {
        'playin': 'South Boston Catholic Academy',
        'firstround': 'South Boston Catholic Academy',
        'quarterfinals': 'South Boston Catholic Academy',
        'semifinals': 'South Boston Catholic Academy',
        'championship': 'South Boston Catholic Academy'
      }

      roundLocation = roundLocationMap[round]
    }

    return (
      <div className='league-bracket-round'>
        <h4 className='text-align-center'>{ roundName }</h4>
        <p className='text-align-center'>{ roundLocation }</p>
        <div className='league-bracket-round-games'>
          { displayPlayoffGames(round) }
        </div>
      </div>
    )
  }

  function displayPlayoffRoundzz(round) {
    let numberOfGames = league.teams.length / (2 ** round)
    if (numberOfGames < 1) {
      return;
    }

    let roundNameMap = {
      '4': 'Quarterfinals',
      '2': 'Semifinals',
      '1': 'Championship'
    }
    let roundName = roundNameMap[numberOfGames.toString()]
    let roundLocationMap = {
      '4': 'South Boston Catholic Academy',
      '2': 'Joseph P. Tynan School',
      '1': 'South Boston Catholic Academy'
    }
    let roundLocation = roundLocationMap[numberOfGames.toString()]

    return (
      <>
        <div className='league-bracket-round'>
          <h4 className='text-align-center'>{ roundName }</h4>
          <p className='text-align-center'>{ roundLocation }</p>
          <div className='league-bracket-round-games'>
            { displayPlayoffGames(round, numberOfGames) }
          </div>
        </div>


        { displayPlayoffRound(round + 1) }
      </>
    );
  }

  function showSignUps () {
    return false;
  }

  function playoffsStarted () {
    return true;

    let unfinishedRegularSeasonGames = league.games.filter((game) => game.status !== 'completed' && game.type == 'regular_season')

    return unfinishedRegularSeasonGames == 0 && gamesExistForSeason();
  }

  function gamesExistForSeason () {
    return league.games.length > 0
  }

  function showAverageStatRow(player, stat) {
    let statLabelAbbv = {
      'assists': 'APG',
      'points': 'PPG',
      'rebounds': 'RPG',
      'blocks': 'BPG',
      'steals': 'SPG'
    }
    let p = utils.getPlayerByID(league.players, player.player_id)
    let playerTeam = utils.getCurrentTeamForPlayerID(league.teams, p.player_id)
    if (!playerTeam) {
      playerTeam = {
        'name': 'No Team'
      }
    }

    return (
      <div className='league-game-player-stat-group'>
        <p className='league-game-player-name' key={player.player_id + '-player-name'}><a className="unstyled-link" href={"/players/" + player.player_id }>{ displayPlayerName(p) }</a> <span className='league-season-average-label'>({ playerTeam.name })</span></p>
        <p className='league-game-player-stat' key={player.player_id + '-player-stat'}>{ parseFloat(player.average.toFixed(1)) } <span className='league-season-average-label'>{ statLabelAbbv[stat] }</span></p>
      </div>
    )
  }
  function getGameForID (game_id){
    return league.games.find((game) => game.id == game_id);
  }
  function getStatsForPlayer (player_id, season, withStealsAndBlocks) {
    let allStatsForPlayer;
    if (withStealsAndBlocks) {
      allStatsForPlayer = league.gameStats.filter((gameStat) => {
        let game = getGameForID(gameStat.game_id)
        return gameStat.player_id == player_id && game && game.date > DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED && gameStat.status == 'played'
      })
    } else {
      allStatsForPlayer = league.gameStats.filter((gameStat) => gameStat.player_id == player_id && gameStat.status == 'played')
    }

    if (season == 0) {
      return allStatsForPlayer;
    } else {
      return allStatsForPlayer.filter((stats) => {
        let game = getGameForID(stats.game_id)
        return game && game.season == season
      })
    }
  }
  function displayPlayerName (player) {
    return `${player.first_name} ${player.last_name}`
  }
  function showTopSeasonStats (stat, season = 0) {
    let seasonPlayers = league.teams.map((team) => team.players.map((id) => utils.getPlayerByID(league.players, id))).flat()

    // Known limitation - there are a few players who have stats but are not in the players table
    // They cannot show up in the top season stats. This if fine because they don't anyways.
    // In the future, we may want to allow this or just create player entries for them.
    seasonPlayers = seasonPlayers.filter((p) => p !== undefined)
    let playerAverages = []

    seasonPlayers.forEach((p) => {
      let allStatsForPlayer;
      if (stat == 'blocks' || stat == 'steals') {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, true);
      } else {
        allStatsForPlayer = getStatsForPlayer(p.player_id, season, false);
      }

      if (allStatsForPlayer.length == 0) {
        return;
      }

      let average = allStatsForPlayer.reduce(function (avg, player, _, { length }) {
        return avg + player[stat] / length;
      }, 0);

      let playerAverage = {
        'player_id': p.player_id,
        'average': average
      };
      if (!isNaN(average)) {
        playerAverages.push(playerAverage)
      }
      // seasonStats[p.id] = allStatsForPlayer;
    })

    let topThreePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 3);

    let topFivePlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 5);

    let topTenPlayerAverages = playerAverages.sort((function(a,b){
      return a.average > b.average ? -1 : 1;
    })).slice(0, 10);

    let statLabel = {
      'assists': 'Assists',
      'points': 'Points',
      'rebounds': 'Rebounds',
      'blocks': 'Blocks',
      'steals': 'Steals'
    }

    // Choose between top 3, top 5, or top 10 players to show
    // let topPlayerAverages = topThreePlayerAverages;
    // let topPlayerAverages = topFivePlayerAverages;
    let topPlayerAverages = topTenPlayerAverages;

    if (topPlayerAverages.length == 0) {
      return;
    } else {
      return (
        <div className='league-player-display'>
          <p className="league-title-standard-small text-center"><b>{ statLabel[stat] }</b></p>
          <div className='league-player-container'>
            <div className='league-game-player-stats'>
              { topPlayerAverages.map((player) => showAverageStatRow(player, stat) ) }
            </div>
          </div>
        </div>
      )
    }
  }

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            { playoffsStarted() ? <>
              <p className="league-title-standard-secondary text-align-center">Playoffs</p>
                { displayPlayoffsButtons() }
              <div className="league-table-container">
                { displayPlayoffs() }
              </div>
            </> :
            <></>
            }

          </div>

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Season { seasonID } { gamesExistForSeason() ? '' : ' (No games yet)' }</p>
            <div className="league-table-container scroll-on-overflow">
              <table className="table league-table">
                <thead className="league-table-heading-hole">
                  <tr>
                    <th className="league-table-heading-name"><span>TEAM</span></th>
                    <th className="league-table-heading hole"><span>W</span></th>
                    <th className="league-table-heading hole"><span>L</span></th>
                    <th className="league-table-heading hole"><span>PCT</span></th>
                    <th className="league-table-heading hole"><span>PF</span></th>
                    <th className="league-table-heading hole"><span>PA</span></th>
                    <th className="league-table-heading hole"><span>+/-</span></th>
                    <th className="league-table-heading hole"><span>Strk</span></th>
                  </tr>
                </thead>
                <thead className="league-table-heading-total">
                  <tr>
                    <th className="league-table-heading-name"><span>TEAM</span></th>
                    <th className="league-table-heading-small"><span>W - L</span></th>
                    <th className="league-table-heading-small"><span>PCT</span></th>
                  </tr>
                </thead>
                <tbody>
                  { displayTeams() }
                </tbody>
              </table>
            </div>
            <div className="league-game-stats-container">
              <p className="league-title-skinny-medium"><b>Season Leaders</b></p>
              { showTopSeasonStats('points', seasonID) }
              { showTopSeasonStats('rebounds', seasonID) }
              { showTopSeasonStats('assists', seasonID) }
              { showTopSeasonStats('blocks', seasonID) }
              { showTopSeasonStats('steals', seasonID) }
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Seasons;
