import React, { Component, useState, useEffect } from 'react';

import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import Homepage from './components/Homepage.js';

import Account from './components/Account.js';
import Login from './components/Login.js';
import Admin from './components/Admin.js';
import AdminPlayers from './components/AdminPlayers.js';
import AdminGames from './components/AdminGames.js';
import AdminSeasons from './components/AdminSeasons.js';
import AdminSeasonTeams from './components/AdminSeasonTeams.js';
import AdminTeams from './components/AdminTeams.js';
import AdminTeamsNew from './components/AdminTeamsNew.js';
import SignUp from './components/SignUp.js';
import Players from './components/Players.js';

import NewPlayer from './components/NewPlayer.js';
import ReturningPlayer from './components/ReturningPlayer.js';
import SignedUpPlayers from './components/SignedUpPlayers.js';

import Game from './components/Game.js';
import OurStory from './components/OurStory.js';
import PlayFor from './components/PlayFor.js';
import Team from './components/Team.js';
import Teams from './components/Teams.js';
import Schedule from './components/Schedule.js'
import Seasons from './components/Seasons.js'
import SouthieTimes from './components/SouthieTimes.js'
import PrayerIntentions from './components/PrayerIntentions.js'
import NewPrayerIntention from './components/NewPrayerIntention.js'


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {

  return (
    <Router>
      <div className="App colorPrimary colorPrimaryHover backgroundColor divider navDividerTop navDividerBottom">
        <Routes>
          <Route exact path="/" element={<Homepage/>}/>

          <Route exact path="/games/:gameID" element={<Game/>}/>

          <Route exact path="/signup" element={<SignUp/>}/>
          <Route exact path="/signup/new" element={<NewPlayer/>}/>
          <Route exact path="/signup/returning" element={<ReturningPlayer/>}/>
          <Route exact path="/signup/all" element={<SignedUpPlayers/>}/>
          <Route exact path="/signup/all/:playerID" element={<SignedUpPlayers/>}/>

          <Route exact path="/players" element={<Players/>}/>
          <Route exact path="/players/:playerID" element={<Players/>}/>

          <Route exact path="/our-story" element={<OurStory/>}/>

          <Route exact path="/teams" element={<Teams/>}/>
          <Route exact path="/teams/:teamID" element={<Team/>}/>
          <Route exact path="/who-do-you-play-for/:id" element={<PlayFor/>}/>

          <Route exact path="/schedule" element={<Schedule/>}/>
          <Route exact path="/schedule/:id" element={<Schedule/>}/>
          <Route exact path="/southietimes" element={<SouthieTimes/>}/>

          <Route exact path="/seasons" element={<Seasons/>}/>
          <Route exact path="/seasons/:seasonID" element={<Seasons/>}/>

          <Route exact path="/prayer-intentions" element={<PrayerIntentions/>}/>
          <Route exact path="/prayer-intentions/:prayerID" element={<PrayerIntentions/>}/>
          <Route exact path="/prayer-intentions/new" element={<NewPrayerIntention/>}/>

          <Route exact path="/account" element={<Account/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/admin" element={<Admin/>}/>
          <Route exact path="/admin/players" element={<AdminPlayers/>}/>
          <Route exact path="/admin/players/:playerID" element={<AdminPlayers/>}/>
          <Route exact path="/admin/games" element={<AdminGames/>}/>
          <Route exact path="/admin/games/:gameID" element={<AdminGames/>}/>
          <Route exact path="/admin/teams/new" element={<AdminTeamsNew/>}/>
          <Route exact path="/admin/teams" element={<AdminTeams/>}/>
          <Route exact path="/admin/teams/:teamID" element={<AdminTeams/>}/>
          <Route exact path="/admin/seasons" element={<AdminSeasons/>}/>
          <Route exact path="/admin/seasons/:season" element={<AdminSeasons/>}/>
          <Route exact path="/admin/seasons/:season/teams/:teamID" element={<AdminSeasonTeams/>}/>
        </Routes>

      </div>
    </Router>
  );
}

export default App;
