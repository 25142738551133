import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

const SignUp = () => {
  return (
    <div className="Schedule">
      <NavBarMini alwaysShown={true} />
      <div className="league-content">

        <div className="league-body">
          <p className="league-title-standard-secondary text-align-center">New or Returning Player?</p>
          <div className="league-table-container schedule large-item-buttons">
            <div className="large-item-button" onClick={() => { window.location.href = '/signup/new'}}>
              <h2>New Player</h2>
              <p>New to League Morning Star!</p>
            </div>
            <div className="large-item-button" onClick={() => { window.location.href = '/signup/returning' }}>
              <h2>Returning Player</h2>
              <p>Played in a previous season of League Morning Star</p>
            </div>
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  );
}

export default SignUp;
