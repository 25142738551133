import React, { Component, useRef, useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const Teams = () => {
  const [league, setLeague] = useState({
    games: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    let updatedTeams = []
    for (const t of league.teams) {
      let seasonTeamData = league.seasonTeams.find((team) => team.id === t.id)

      let team;
      if (seasonTeamData) {
        team = { ...t, ...seasonTeamData }
      } else {
        team = { ...t }
      }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }

  function displayTeamName (team) {
    return (
      <>
        <li><a key={team.id} className="league-link" href={ '/teams/' + team.id }>{ team.name.toUpperCase() }</a></li>
      </>
    )
  }

  function getPlayerByID(id) {
    league.players.find((p) => p.player_id == id);
  }

  function displayTeamNames () {
    let teamElements = league.teams.map((team, place) => displayTeamName(team) )
    return teamElements;
  }

  if (dataReady == false) {
    return (
      <div className="Teams">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Teams">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Teams</p>
            <div className="league-table-container league-teams-list">
              <ul className="nav-links-mini">
                { displayTeamNames() }
              </ul>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Teams;
