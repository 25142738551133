import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const production = 'league_data';
const test = 'league_data_test';
const SEASON = '2'
const production_new = `league_morning_star_${SEASON}`

const DATABASE_SOURCE = production_new;

const PlayFor = () => {
  const { id } = useParams();
  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    setLeague(league);
    setDataReady(true);
  }

  function getTeamForID(id) {
    return league.teams.filter((team) => team.id == id)[0];
  }

  function displayTeamName () {
    if (league.teams.length > 0) {
      let saint_name = getTeamForID(id).name

      return 'St. ' + saint_name
    } else {
      return ''
    }
  }

  function displayTeamBioParagraph (p) {
    return (
      <>
        <p className="">{ p }</p>
      </>
    )
  }

  function displayTeamBio () {
    let team = getTeamForID(id);

    let bioParagraphs = team.bio.map((paragraph) => displayTeamBioParagraph(paragraph));
    return bioParagraphs;
  }

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="PlayFor">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <p className="league-title-standard">{ displayTeamName() }</p>
            </div>
          </div>

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Who Do You Play For?</p>
            <p className="">
              When it comes to magical runs, some of the teams are obvious: Jimmy V and the 1983 Wolfpack, the 2004 “Cowboy Up” Red Sox, and of course the 1980 Miracle on Ice. Though these unforgettable teams played different sports, they all had that “it” factor in common. You know, that thing in teamsports you can’t define, but you just know it when you see it. That competitive edge, that swagger, that sense of belief, that shared identity that makes a group of ordinary men giant killers.
            </p>
            <p className="">
              Another thing is clear about the “it” factor.You can’t manufacture or force it. The “It” factor is like the mustard seed in the Gospel. It grows little by little as it is watered by suffering and perseverance. We all know that a team either rises or crumbles when it faces adversity. The same is true in life. Suffering and hardships either destroy us or form us into men of God.
            </p>
            <p className="">
              The Saints that protect and represent League Morning Star understood this very well. These men were much like you and me. They too struggled, suffered, and doubted the goodness of God. And yet, through perseverance and most importantly the power of God’s grace, they became men that stood in the breach and led culture changing initiatives. Their lives demonstrate the victory of God in every age and that holiness is possible for ordinary men. Take a look at the descriptions below. Afterall, you need to answer Herb Brooks’ perennial question, “Who do you play for?”
            </p>
            <hr className="league-page-break"/>
            { displayTeamBio() }
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default PlayFor;
