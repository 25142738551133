import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const AdminTeamsNew = () => {
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})
  const [team, setTeam] = useState({})

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          getLeague()
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    let updatedTeams = []
    for (const t of league.teams) {
      let seasonTeamData = league.seasonTeams.find((team) => team.id === t.id)

      let team;
      if (seasonTeamData) {
        team = { ...t, ...seasonTeamData }
      } else {
        team = { ...t }
      }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }

  function displayEditTeam () {
    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label for="inputTeamName">Team Name</label>
              <input type="text" className="form-control" id="inputTeamName" required></input>
            </div>
            <div className="form-group">
              <label for="inputTeamNameAbbv">Team Name Abbreviated</label>
              <input type="text" className="form-control" id="inputTeamNameAbbv" required></input>
            </div>
          </div>
          <div className="form-group">
            <label for="inputTeamID">ID</label>
            <input type="text" className="form-control" id="inputTeamID" required></input>
          </div>
          <div className="form-group">
            <label for="inputTeamBio">Bio</label>
            <textarea type="text" className="form-control" id="inputTeamBio" required></textarea>
          </div>
          <button className="btn btn-dark" type="button" onClick={saveTeam}>Create New Team</button>
        </form>
      </>
    )
  }

  function saveTeam () {
    let tm = {
      bio: document.getElementById('inputTeamBio').value.split('\n'),
      id: document.getElementById('inputTeamID').value,
      name: document.getElementById('inputTeamName').value,
      name_abbv: document.getElementById('inputTeamNameAbbv').value
    }


    league.teams.push(tm)

    saveLeague(tm.id);
  }

  const saveLeague = async (teamID) => {
    console.log('saving league')
    await setDoc(doc(db, "league", DATABASE_SOURCE), league);
    console.log('redirect')
    window.location.href = `/teams/${teamID}`
  }

  if (dataReady == false || currentUser == null) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  } else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-body">
            <p className='league-admin-back-button'><a className='league-link' href="/admin"><b>← Back to Admin Home</b></a></p>
            <p className='league-admin-back-button'><a className='league-link' href="/admin/teams"><b>← Back to Admin &gt; Teams</b></a></p>

            <p className="league-title-standard-secondary text-align-center">Create Team</p>
            <div className="league-table-container schedule large-item-buttons">

            { displayEditTeam() }
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default AdminTeamsNew;
