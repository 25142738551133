import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

// League Constants
export function playoffsStarted (league) {
  if (league.games.length == 0) {
    return false;
  }

  const unfinishedRegularSeasonGames = league.games.filter((game) => game.status !== 'completed' && game.type == 'regular_season')

  return unfinishedRegularSeasonGames == 0;
}
export function getCurrentPlayoffRound () {
  return 'championship';
}
export function showSeasonLeaders (league) {
  if (league.games.length == 0) {
    return false;
  }

  const seasonTeams = league.teams
  const finishedGames = league.games.filter((game) => game.status == 'completed')
  return finishedGames.length / seasonTeams.length > 2;
}

export function getCurrentOrMostRecentSeason () {
  return '3'
}
export function getUpcomingSeason () {
  return (parseInt(getCurrentOrMostRecentSeason()) + 1).toString()
}
export function getCurrentOrMostRecentSeasonStartDate () {
  // Season 3
  return new Date('October 15, 2024');
  // Season 2
  // return new Date('May 13, 2024');
}
export function getUpcomingSeasonStartDate () {
  return new Date('October 15, 2024');
}

export function showSignUps () {
  return false;
}

export function showPlayoffBracket () {
  return false;
}

export function getLocationName (location, abbv=false) {
  if (location == 'sbca') {
    if (abbv) {
      return 'SBCA'
    } else {
      return "South Boston Catholic Academy"
    }
  } else if (location == 'b&gc') {
    if (abbv) {
      return "B&GC"
    } else {
      return "Boys & Girls Club"
    }
  }
}

export function displayTeamConference (team) {
  console.log(team)
  if (team.conference == 'west') {
    return 'Western Conference'
  } else if (team.conference == 'east') {
    return 'Eastern Conference'
  }
}

// Data Access
export function getTeamForID(teams, id) {
  return teams.find((team) => team.id == id);
}
export function getCurrentTeamForPlayerID (teams, player_id) {
  return teams.find((team) => team.players.includes(player_id))
}
export function getGameForID (games, id) {
  return games.find((game) => game.id == id);
}
export function getPlayerByID(players, id) {
  return players.find((player) => player.player_id == id);
}



// Database, Admin, etc.
export function getDatabaseSource () {
  const season = getCurrentOrMostRecentSeason();

  const production = `league_morning_star_2`;
  const test = `league_morning_star_2_test`;

  return production;
}

export async function syncTestDatabaseWithProduction (league) {
  const season = getCurrentOrMostRecentSeason();

  const production = `league_morning_star_2`;
  const test = `league_morning_star_2_test`;
  await setDoc(doc(db, "league", test), league);
}

async function syncProdDatabaseWithTest (league) {
  // await setDoc(doc(db, "league", production), league);
}


// Interpolated Team Data
export function getRecordForID (games, team_id) {
  let season = getCurrentOrMostRecentSeason()
  if (team_id) {
    let allGames = games.filter((game) => game.status === 'completed' && game.season == season  && (game.away_team_id === team_id || game.home_team_id === team_id))
    let allGamesWon = games.filter((game) => game.status === 'completed' && game.season == season && (game.winner === team_id))
    let gamesPlayed = allGames.length;
    let gamesWon = allGamesWon.length;
    return `${gamesWon}-${gamesPlayed - gamesWon}`
  } else {
    return '';
  }
}

// Interpolated Player Data
export function displayPlayerName (player) {
  return `${player.first_name} ${player.last_name.replace("’", "'")}`
}
export function displayPlayerHeight (player) {
  const height = player.height
  return `${height.feet}' ${height.inches}"`
}
export function displayJerseyNumber (player) {
  if (player.jersey_number == '') {
    return ``
  }
  return `#${player.jersey_number}`
}

// Miscallaneous
export function abbvWeekday (idx) {
  let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return weekday[idx];
}

export function isAdminEmail (email) {
  let adminEmails = [
    'ccrane2@alumni.nd.edu',
    'matthew.chignoli@gmail.com'
  ]

  return adminEmails.includes(email)
}