import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';

import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const PrayerIntentions = () => {
  const savePrayerIntention = async () => {
    const docRef = await addDoc(collection(db, "prayer_intentions"), {
      created_at: Timestamp.fromDate(new Date()),
      name: document.getElementById('inputName').value,
      message: document.getElementById('inputPrayer').value,
      status: 'new'
    });
    console.log("Document written with ID: ", docRef.id);

    window.location.href = `/prayer-intentions/${docRef.id}`
  }

  function displayNewPrayerIntention () {
    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label for="inputName">Name</label>
              <input type="text" className="form-control" id="inputName" placeholder="Anonymous"></input>
            </div>
          </div>
          <div className="form-group">
            <label for="inputPrayer">Prayer</label>
            <textarea type="text" className="form-control" id="inputPrayer" required></textarea>
          </div>
          <button className="btn btn-dark" type="button" onClick={savePrayerIntention}>Create Prayer Intention</button>
        </form>
      </>
    )
  }

  return (
    <div className="NewPrayerIntention">
      <NavBarMini alwaysShown={true} />
      <div className="league-content">
        <div className="league-header">
          <div className="league-header-top">
            <p className="league-title-standard-secondary text-align-center">Write a prayer intention</p>
          </div>
        </div>
        <div className="league-body">
          <p className="">Enter a prayer intention and we will be sure to pray for you and that intention. All prayer intentions are anonymous unless you'd like to leave your name.</p>

          <div className="dropdown league-dropdown">
          </div>
          <div className="league-table-container schedule">
            { displayNewPrayerIntention() }
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  );
}

export default PrayerIntentions;
