import React, { Component, useRef, useState, useEffect } from 'react';
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';

import { useParams } from "react-router-dom";

import { collection, query, where, getDoc, getDocs, setDoc, doc } from "firebase/firestore";
import { db } from '../firebase.js';

const OurStory = () => {
  const [article, setArticle] = useState({});
  const [author, setAuthor] = useState({});

  const renderHeaderTop = () => {
    return (
      <div className="article-header-top">
        <p className="article-type">Our Story</p>
        <p className="article-type-secondary">{ article.type_secondary }</p>
        <p className="article-title article-title-standard">The Story of League Morning Star</p>
        <p className="article-date article-date-lg">Mar. 18, 2024</p>
      </div>
    )
  }

  const renderHeaderBottom = () => {
    let size = 'lg';

    return (
      <div className={"article-header-bottom article-header-bottom-" + size }>
        <div className={"article-author article-author-" + size }>
          <img className={"author-image author-image-" + size } style={{objectFit: 'cover'}} src="https://lirp.cdn-website.com/958e8a03/dms3rep/multi/opt/Rev.-Mr.-Peter-Schirripa-1920w.jpg"/>
          <div className="author-metadata">
            <p className={"author-title author-title-" + size } ><span>By</span> <span className="author-name"><a href={ author.profile_url }>Fr. Peter Schirripa</a></span></p>
            <p className="author-bio">Fr. Schirripa is the Parochial Vicar of St. Brigid and Gate of Heaven Parishes.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="OurStory">
      <NavBarMini alwaysShown={true} />
      <div className="article-content">
        <div className="article-header">
          { renderHeaderTop(article) }
          { renderHeaderBottom() }
        </div>

        <div className="article-body">
          <div key="paragraph" className="article-paragraph">
            <p>It began as 2-on-2 pickup after Mass one summer afternoon. Next thing you know, League Morning Star was formed, exploding into Boston's fastest-growing men’s basketball league. And with its expansion from its original eight teams to a dozen for the upcoming summer session, it's still growing. We're not talking, however, about just basketball. League Morning Star hoopers become part of a family, where friendships and brotherhood extend well beyond the hardwood. Morning Star friendships are formed and fostered by the prayer and sacrifice required to compete in a league that tips off at 6:30 in the morning when most of the city is still asleep. It's founded on the truth that men need real and virtuous camaraderie to flourish, which is why the league exists: to build natural virtue and to introduce men to the Person of Jesus Christ and His Catholic Church. And with that, Morning Star believes young men in particular play a pivotal role in reigniting Catholic Culture on the hardwood and beyond. Let's face it: A lot of men in today's world aren't knocking on the doors of the Church. League Morning Star exists to find them, to bring them home. In the most natural, human way, our hoopers begin to discover that, all along, and even in the darkest moments of life, Our Lady Morning Star shines brightly, showing them the Way. </p>
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  );
}

export default OurStory;
