import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const DATABASE_SOURCE = utils.getDatabaseSource();

let DATE_WHEN_BLOCKS_AND_STEALS_WERE_ADDED = new Date('May 27, 2024');
let SAVE_BLOCKS_AND_STEALS = true;

const AdminSeasonTeams = () => {
  const { teamID, season } = useParams();
  const [league, setLeague] = useState({
    games: [],
    gameStats: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({})
  const [team, setTeam] = useState({})
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [allPlayersInSeason, setAllPlayersInSeason] = useState([]);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser)

        if (utils.isAdminEmail(currentUser.email)) {
          await getLeague()

          const players = await getAllSeasonPlayers()
          await setAllPlayersInSeason(players)
        } else {
          window.location.href = "/";
        }
      } else {
        setCurrentUser(null)
        window.location.href = "/login";
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [])

  useEffect(() => {
    if (allPlayersInSeason.length === 0) {
      return;
    }
    const filtered = searchTerm.trim() === ''
      ? [] // Show no results if searchTerm is blank
      : allPlayersInSeason.filter(player =>
          `${player.first_name} ${player.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
        );

    setFilteredPlayers(filtered);
  }, [searchTerm]);

  const getAllSeasonPlayers = async () => {
    const signupsSnapshot = await getDocs(collection(db, "signups"));
    let signups = []
    signupsSnapshot.forEach((doc) => {
      const id = doc.id
      const signup = doc.data()
      signup.id = id
      signups.push(signup)
    });

    const currentTeamPlayerIDs = teamPlayers.map((player) => player.player_id)

    let playerIDsThisSeason = signups.filter((signup) => signup.season == season && signup.paid === true)
    let paidPlayers = playerIDsThisSeason.map((signup) => utils.getPlayerByID(league.players, signup.player_id))
    let playersNotAlreadyOnTeam = paidPlayers.filter((player) => player && !currentTeamPlayerIDs.includes(player.player_id))

    return playersNotAlreadyOnTeam
  };

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    if (teamID) {
      const tm = utils.getTeamForID(league.teams, teamID)

      setTeam(tm)
    }

    const seasonTeam = league.seasonTeams.find((team) => team.season == season && team.team_id == teamID)
    let players = seasonTeam.players.map((player_id) => utils.getPlayerByID(league.players, player_id))
    setTeamPlayers(players)

    setLeague(league);
    setDataReady(true);
  }

  function addPlayer (player_id) {
    const player = utils.getPlayerByID(league.players, player_id)
    let players = teamPlayers
    players.push(player)
    setTeamPlayers(players)
    setSearchTerm('')
  }

  function removePlayer(player_id) {
    const updatedTeamPlayers = teamPlayers.filter(player => player.player_id !== player_id);

    setTeamPlayers(updatedTeamPlayers);
  }

  function displayEditSeasonTeam () {
    return (
      <>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="inputTeamName">Team Name</label>
              <input type="text" className="form-control" id="inputTeamName" defaultValue={team.name} required disabled />
            </div>
            <div className="form-group">
              <label htmlFor="inputTeamNameAbbv">Team Name Abbv.</label>
              <input type="text" className="form-control" id="inputTeamNameAbbv" defaultValue={team.name_abbv} required disabled />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="inputPlayerSearch">Search for player</label>
            <input type="text" className="form-control" id="playerSearch"
              placeholder="Player name"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}>
            </input>
          </div>
          {searchTerm.trim() === '' ? ( <></>
            ) : (
            filteredPlayers.length > 0 ? (
              <div className='returning-player-search-results'>
                {filteredPlayers.map((player, index) => (
                  <div className='returning-player-signup'>
                    <p key={index}>
                      {player.first_name} {player.last_name}
                    </p>
                      <button className="btn btn-success" type="button" onClick={() => addPlayer(player.player_id)}>+ Add Player</button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No players found</p>
                )
            )}
          <div className="form-group">
            <label>Players</label>
            <ul className="list-group">
              { teamPlayers.map((player, index) => (
                <div className='league-admin-season-team'>
                  <input key={index} className="form-control" value={utils.displayPlayerName(player)}></input>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => removePlayer(player.player_id)}
                  >
                    Delete
                  </button>
                </div>

              ))}
            </ul>
          </div>
          <button className="btn btn-dark" type="button" onClick={saveTeam}>Save Team</button>
        </form>
      </>
    )
  }

  async function saveTeam () {
    const currentSeasonTeam = league.seasonTeams.find((team) => team.season == season && team.team_id == teamID)
    currentSeasonTeam.players = teamPlayers.map((player) => player.player_id)

    saveLeague();
  }

  const saveLeague = async () => {
    console.log('saving league')
    await setDoc(doc(db, "league", DATABASE_SOURCE), league);
    console.log('redirect')
    window.location.href = `/admin/seasons/${season}`
  }

  function createTeam () {
    window.location.href = "/admin/teams/new";
  }

  if (dataReady == false || !team || team == {}) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-body">
            <p className='league-admin-back-button'><a className='league-link' href="/admin"><b>← Back to Admin Home</b></a></p>
            { teamID ? <p className='league-admin-back-button'><a className='league-link' href={"/admin/seasons/" + season}><b>← Back to Season { season }</b></a></p> : '' }

            <p className="league-title-standard-secondary text-align-center">Edit Team - St. {team.name}</p>
            { teamID ? '' : <a type="button" className="btn btn-dark" onClick={createTeam}>+ Create Team</a> }
            <div className="league-table-container schedule large-item-buttons">
              { displayEditSeasonTeam() }
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default AdminSeasonTeams;
