import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

const SignedUpPlayers = () => {
  const { playerID } = useParams();
  const [signups, setSignups] = useState([]);
  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();
    setLeague(league);

    const signupsSnapshot = await getDocs(collection(db, "signups"));
    const signups = []

    // Used this code to add paid: true to all signups
    // signupsSnapshot.forEach(async (docSnapshot) => {
    //   const signupRef = doc(db, "signups", docSnapshot.id);
    //   await updateDoc(signupRef, { paid: true });
    // });

    signupsSnapshot.forEach((doc) => {
      const id = doc.id
      const signup = doc.data()
      signup.id = id
      signups.push(signup)
    });

    let signupsSortedNewestToOldest = signups.sort(function(s1,s2){
      let s1_date = new Date(s1.created_at.seconds * 1000 + s1.created_at.nanoseconds / 1000000)
      let s2_date = new Date(s2.created_at.seconds * 1000 + s2.created_at.nanoseconds / 1000000)
      return s2_date - s1_date;
    })
    setSignups(signupsSortedNewestToOldest)
    setDataReady(true);
  }

  function getPlayerByPlayerID (playerID) {
    return league.players.find((player) => player.player_id == playerID)
  }

  function displayPlayerNameRow (player, place=0) {
    return (
      <div className='league-player-list-player'>
        <p key={player.player_id + '-player-name'}>{ place + 1 }. <a className="unstyled-link" href={"/players/" + player.player_id }>{ utils.displayPlayerName(player) }</a></p>
      </div>
    )
  }

  async function togglePaid (player, signup) {
    let playerCheckbox = 'paidCheckbox-' + player.player_id
    let checkbox = document.getElementById(playerCheckbox)

    try {
      const signupsCollection = await collection(db, "signups");
      const q = query(signupsCollection, where("player_id", "==", signup.player_id), where("season", "==", signup.season));

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Update the paid attribute for the first matching document
        const docSnapshot = querySnapshot.docs[0];
        const signupRef = doc(db, "signups", docSnapshot.id);
        await updateDoc(signupRef, { paid: checkbox.checked });
        console.log(`Paid attribute updated for player ID: ${signup.player_id}, season: ${signup.season}`);
    } else {
        console.log(`No document found for player ID: ${signup.player_id}, season: ${signup.season}`);
      }
    } catch (error) {
      console.log(error)
    }
  }

  function displayPlayer (player, place=0, showJustName=false, signup) {
    if (showJustName) {
      return (
        <div className='league-player-list-player'>
          <div>
            <p className={ 'league-player-' + signup.status } key={player.player_id + '-player-name'}>{ place + 1 }. <a className="unstyled-link" href={"/players/" + player.player_id }>{ utils.displayPlayerName(player) }</a></p>
            <p>Email: { player.email }</p>
            <p>Phone: { player.phone }</p>
            <p>Height: { player.height.feet }' { player.height.inches }"</p>
            <p>Age: { player.age }</p>
            <p>Jersey Size: { player.jersey_size}</p>
            <p>ID: { player.player_id }</p>
          </div>
          <div>
            <div className='mb-3 admin-edit-col centered-edit-col'>
              <label className="form-check-label">
                Paid?
              </label>
              <input className="form-check-input big-checkbox" type="checkbox" value="" id={"paidCheckbox-" + player.player_id} defaultChecked={ signup.paid } onClick={ () => { togglePaid(player, signup) }}/>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <p className="league-title-standard-secondary text-align-center">Welcome to League Morning Star!</p>
          <a className="league-type league-mini-header-link" href="/">View Homepage</a>
          <h4 className='text-align-center'>New Player</h4>
          <p>Name: { player.first_name } { player.last_name }</p>
          <p>Email: { player.email }</p>
          <p>Phone: { player.phone }</p>
          <p>Height: { player.height.feet }' { player.height.inches }"</p>
        </>
      )
    }
  }

  function displayPlayerEmail (player) {
    return (
      <>
       <p>{ player.email }</p>
      </>
    )
  }

  function displayAllPlayersEmails () {
    return (
      <>
      {
      league['players'].map((player) => displayPlayerEmail(player))
      }
      </>
    )
  }

  function copyEmails () {
    let players = signups.map((signup) => getPlayerByPlayerID(signup.player_id))
    const emails = players.map(player => player.email).join(', ');

    navigator.clipboard.writeText(emails)
    .then(() => {
      alert('Emails copied to clipboard!');
    })
    .catch(err => {
      console.error('Failed to copy emails: ', err);
    });
  }

  function showOldPlayersNotSignedUp () {
    const seasonPlayerIDs = signups.map((signup) => signup.player_id)
    const allPlayerIDs = league.players.map((player) => player.player_id)
    const notReturningPlayerIDs = allPlayerIDs.filter(p => !seasonPlayerIDs.includes(p))

    return (
      <>
        { notReturningPlayerIDs.map((playerID, place) => displayPlayerNameRow(getPlayerByPlayerID(playerID), place))}
      </>
    )
  }

  const generateCSVWithNamesAndHeights = () => {
    const headers = ['Name', 'Height'];
    const rows = league.players.map(player => [utils.displayPlayerName(player), utils.displayPlayerHeight(player)]);

    let csvContent = headers.join(',') + '\n';
    rows.forEach(row => {
      csvContent += row.join(',') + '\n';
    });

    return csvContent;
  };

  const downloadNamesAndHeights = () => {
    const csvData = generateCSVWithNamesAndHeights();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'players.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function displayAllPlayers () {
    let paidSignUps = signups.filter((signup) => signup.paid === true)

    let newSignUps = signups.filter((signup) => signup.status === 'new')
    let newPlayers = newSignUps.map((signup) => utils.getPlayerByID(league.players, signup.player_id))

    let newPaidSignUps = signups.filter((signup) => signup.status === 'new' && signup.paid === true)
    let newPaidPlayers = newPaidSignUps.map((signup) => utils.getPlayerByID(league.players, signup.player_id))

    return (
      <>
        <p className="league-title-standard-secondary text-align-center">{ signups.length } Signed Up Player{ signups.length < 2 ? '' : 's' } ({ newPlayers.length } new)</p>
        <p className="league-title-standard-secondary text-align-center">{ paidSignUps.length } Paid Player{ paidSignUps.length < 2 ? '' : 's' } ({ newPaidPlayers.length } new)</p>

        <button className="btn btn-dark" type="button" onClick={copyEmails}>Copy All Emails</button>
        <button className="btn btn-dark" type="button" onClick={downloadNamesAndHeights}>Download Names and Heights</button>

        <div className="league-flex-accordion-container">
          <div class="accordion accordion-flush league-accordion" id="accordionExample">
            <div class="accordion-item league-accordion-item">
              <h2 class="accordion-header league-accordion-header" id="headingOne">
                <button class="accordion-button league-accordion-button collapsed league-title-skinny-small-extended-width" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Old Players Not Yet Signed Up
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body league-accordion-body">
                  <div className="league-table-container schedule scroll-on-overflow">
                    { showOldPlayersNotSignedUp() }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>Jersey Sizes for new players - Format: Total (Paid)</p>
        <div className='league-jersey-count'>
          <p>S: { newPlayers.filter((player) => player.jersey_size == 'S').length} ({ newPaidPlayers.filter((player) => player.jersey_size == 'S').length})</p>
          <p>M: { newPlayers.filter((player) => player.jersey_size == 'M').length} ({ newPaidPlayers.filter((player) => player.jersey_size == 'M').length})</p>
          <p>L: { newPlayers.filter((player) => player.jersey_size == 'L').length} ({ newPaidPlayers.filter((player) => player.jersey_size == 'L').length})</p>
          <p>XL: { newPlayers.filter((player) => player.jersey_size == 'XL').length} ({ newPaidPlayers.filter((player) => player.jersey_size == 'XL').length})</p>
          <p>2XL: { newPlayers.filter((player) => player.jersey_size == 'XXL').length} ({ newPaidPlayers.filter((player) => player.jersey_size == 'XXL').length})</p>
        </div>

        { signups.map((signup, place) => displayPlayer(getPlayerByPlayerID(signup.player_id), place, true, signup))}
      </>
    )
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <div className="dropdown league-dropdown">
            </div>
            <div className="league-table-container schedule">
              {
                playerID ?
                displayPlayer(getPlayerByPlayerID(playerID), true)
                :
                displayAllPlayers()
              }
              { playerID ? '' : ''
              //displayAllPlayersEmails()
              }
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default SignedUpPlayers;
