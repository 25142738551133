import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  return (
    <div className="Footer">
      <div className="article-footer">

        <ul className="footer-copyright">
          <li><a href="">©&nbsp;<span>{ new Date().getFullYear() }</span>&nbsp;<span>The C3 Company</span></a></li>

        </ul>
      </div>
    </div>
  );
}

export default Footer
