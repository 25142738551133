import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';
import { getPlayerByID } from '../common/Utils.js';

const SEASON = utils.getCurrentOrMostRecentSeason();
const DATABASE_SOURCE = utils.getDatabaseSource();

const Team = () => {
  const { teamID } = useParams();
  const [league, setLeague] = useState({
    games: [],
    players: [],
    seasonTeams: [],
    teams: []
  });
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getLeague();
  }, [])

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();

    let sortedGames = league.games.sort(function(a,b){
      return new Date(a.date) - new Date(b.date);
    });
    league.games = sortedGames.map((game) => {
      game.date = game.date.toDate();
      return game;
    });

    let updatedTeams = []
    for (const t of league.teams) {
      let seasonTeamData = league.seasonTeams.find((seasonTeam) => seasonTeam.team_id === t.id && seasonTeam.season == SEASON)

      let team;
      if (seasonTeamData) {
        team = { ...t, ...seasonTeamData }
      } else {
        team = { ...t }
      }

      updatedTeams.push(team)
    }
    league.teams = updatedTeams

    setLeague(league);
    setDataReady(true);
  }

  function getTeamForID(id) {
    return league.teams.filter((team) => team.id == id)[0];
  }

  function displayTeamName () {
    if (league.teams.length > 0) {
      return 'St. ' + getTeamForID(teamID).name
    } else {
      return ''
    }
  }

  function displayPlayer (player) {
    let captainClass = '';
    let team = getTeamForID(teamID)
    if (player.player_id == team.captain) {
      captainClass = 'captain'
    }
    return (
      <tr key={player.player_id + "-team-row"}>
        <td className={ `league-table-data-name user-name ${captainClass}` }><a className="unstyled-link" href={"/players/" + player.player_id }>{ displayJerseyNumber(player) } { displayPlayerName(player) }</a></td>
        <td className="league-table-data wide"><span>{ displayPlayerHeight(player) || '-' }</span></td>
      </tr>
    );
  }

  function displayJerseyNumber (player) {
    if (player.jersey_number == '') {
      return ``
    }
    return `#${player.jersey_number}`
  }

  function displayPlayerHeight (player) {
    const height = player.height
    return `${height.feet}' ${height.inches}"`
  }

  function displayPlayerName (player) {
    return `${player.first_name} ${player.last_name}`
  }

  function jerseyNumberSort (a, b) {
    if (a.jersey_number == '' && b.jersey_number == '') {
      return 0;
    } else if (a.jersey_number == '') {
      return 1;
    } else if (b.jersey_number == '') {
      return -1;
    } else {
      let number1 = parseInt(a.jersey_number)
      let number2 = parseInt(b.jersey_number)

      return number1 < number2 ? -1 : 1
    }
  }

  function displayPlayers () {
    if (league.players.length == 0){
      return;
    }

    let teamPlayers = []
    try {
      teamPlayers = getTeamForID(teamID).players.map((player_id) => getPlayerByID(league.players, player_id))
    } catch {
      console.log('No players for team.')
    }

    let sortedPlayers = teamPlayers.sort((a, b) => jerseyNumberSort(a, b))
    let playerElements = sortedPlayers.map((player, place) => displayPlayer(player))
    return playerElements;
  }

  function getRecordForID (given_id = null) {
    let team_id = given_id === null ? teamID : given_id

    if (team_id) {
      let allGames = league.games.filter((game) => game.status === 'completed' && game.season == SEASON  && (game.away_team_id === team_id || game.home_team_id === team_id))
      let allGamesWon = league.games.filter((game) => game.status === 'completed' && game.season == SEASON && (game.winner === team_id))
      let gamesPlayed = allGames.length;
      let gamesWon = allGamesWon.length;
      return `${gamesWon}-${gamesPlayed - gamesWon}`
    } else {
      return '';
    }
  }

  function displayLastGame () {
    let game = getLastGame();

    if (!game) {
      return;
    }

    return (
      <>
        <p className="league-game-label-small text-align-center">Last Game</p>
        { displayFinishedGameThisWeek(game) }
      </>
    )
  }

  function displayNextGame () {
    let game = getNextGame();

    if (!game) {
      return;
    }

    return (
      <>
        <p className="league-game-label-small text-align-center">Next Game</p>
        { displayGameThisWeek(game) }
      </>
    )
  }

  function getLastGame () {
    let allCompletedGames = league.games.filter((game) => game.status === 'completed' && game.season == SEASON && (game.away_team_id === teamID || game.home_team_id === teamID))
    return allCompletedGames[allCompletedGames.length - 1];
  }

  function getNextGame () {
    let allScheduledGames = league.games.filter((game) => game.status === 'scheduled' && game.season == SEASON && (game.away_team_id === teamID || game.home_team_id === teamID))
    return allScheduledGames[0];
  }

  function abbvWeekday (idx) {
    let weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    return weekday[idx];
  }

  function displayGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }


    return (
      <div className='league-game-container stretch-game-width' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away-name'}>{ away_team.name.toUpperCase() }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home-name'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-records'>
            <p className='league-game-team-record' key={game.id + '-away-record'}>{ getRecordForID(game.away_team_id) }</p>
            <p className='league-game-team-record' key={game.id + '-home-record'}>{ getRecordForID(game.home_team_id) }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'>{ abbvWeekday(game.date.getDay()) }, { game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
          <p className='league-game-time'>{ hours }:{ game.date.getMinutes() } { hours_label }</p>
          <p className='league-game-time'>{ utils.getLocationName(game.location, true) }</p>
        </div>
      </div>

    )
  }

  function displayFinishedGameThisWeek (game) {
    // let gameID = `${game.away_team_id}-vs-${game.home_team_id}-${game.date.getMonth() + 1}-${game.date.getDate()}-${game.date.getFullYear()}`

    let away_team = getTeamForID(game.away_team_id);
    let home_team = getTeamForID(game.home_team_id);

    let hours = game.date.getHours();
    let hours_label = 'AM'
    if(hours >= 12){
      hours_label = 'PM';
    }

    if(hours > 12){
      hours -= 12;
    }
    if(hours == 0){
      hours = 12;
    }
    return (
      <div className='league-game-container stretch-game-width' onClick={() => {
        window.location.href = `/games/${game.id}`
      }} key={game.id + '-game-container'}>
        <div className='league-game-team-names-and-scores'>
          <div className='league-game-team-names'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-away'}>{ away_team.name.toUpperCase() }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-name game-winner' : 'league-game-team-name' } key={game.id + '-home'}>{ home_team.name.toUpperCase() }</p>
          </div>
          <div className='league-game-team-scores'>
            <p className={ game.winner === game.away_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-away-score'}>{ game.status === 'completed' ? game.score.away : '' }</p>
            <p className={ game.winner === game.home_team_id ? 'league-game-team-score game-winner' : 'league-game-team-score' } key={game.id + '-home-score'}>{ game.status === 'completed' ? game.score.home : '' }</p>
          </div>
        </div>
        <div className='league-game-time-and-stats'>
          <p className='league-game-time'><b>{ game.status === 'completed' ? 'Final' : 'Pending' }</b></p>
          <p className='league-game-time'>{ game.date.getMonth() + 1 }/{ game.date.getDate() }</p>
        </div>
      </div>
    )
  }

  let this_team = utils.getTeamForID(league.teams, teamID)

  if (dataReady == false) {
    return (
      <div className="Homepage">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">
          <div className="league-header">
            <div className="league-header-top">
              <p id="teamName" className="league-title-standard-secondary">{ displayTeamName() }</p>
              <p id="teamName" className="league-title-standard-secondary">{ utils.displayTeamConference(this_team) }</p>
              <a className="league-title-standard-small league-title-link text-align-center" href={ '/who-do-you-play-for/' + teamID}>Who Do You Play For?</a>
            </div>
          </div>

          <div className="league-body">
            <p className="league-title-standard-small">({ getRecordForID() })</p>
            <a className="league-type league-mini-header-link" href={`/schedule/${teamID}`}>All Team Games</a>
            <div className="league-table-container team-page">
              { displayLastGame() }
              { displayNextGame() }
            </div>
          </div>

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Roster</p>
            <div className="league-table-container">
              <table className="table league-table">
              <thead className="league-table-heading-hole">
                  <tr>
                    <th className="league-table-heading-name"><span>PLAYER</span></th>
                    <th className="league-table-heading hole"><span>HEIGHT</span></th>
                  </tr>
                </thead>
                <thead className="league-table-heading-total">
                  <tr>
                    <th className="league-table-heading-name"><span>PLAYER</span></th>
                    <th className="league-table-heading-small"><span>HEIGHT</span></th>
                  </tr>
                </thead>
                <tbody>
                  { displayPlayers() }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default Team;
