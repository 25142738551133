import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, addDoc, updateDoc, doc, Timestamp, collectionGroup } from "firebase/firestore";
import { db } from '../firebase.js';

const PAST_SEASON = utils.getCurrentOrMostRecentSeason();
const UPCOMING_SEASON = utils.getUpcomingSeason();
const SEASON_START_DATE = utils.getUpcomingSeasonStartDate();

const DATABASE_SOURCE = utils.getDatabaseSource();

const ReturningPlayer = () => {
  const [league, setLeague] = useState({
    games: [],
    players: [],
    teams: [],
    gameStats: []
  });
  const [dataReady, setDataReady] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [signups, setSignups] = useState([]);

  useEffect(() => {
    getLeague();
  }, [])

  useEffect(() => {
    if (!dataReady) return;

    const getAllPastPlayers = () => {
      let allStatIDs = league.gameStats.map(stat => stat.player_id);
      let playerIDs = [...new Set(allStatIDs)];
      let players = playerIDs.map(player_id => getPlayerByPlayerID(player_id));
      return players.filter(player => player); // Filter out any undefined players
    };

    const allPlayers = getAllPastPlayers();

    const filtered = searchTerm.trim() === ''
    ? [] // Show no results if searchTerm is blank
    : allPlayers.filter(player =>
        `${player.first_name} ${player.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
      );

    setFilteredPlayers(filtered);
  }, [searchTerm, dataReady, league]);

  const getLeague = async () => {
    const docRef = doc(db, "league", DATABASE_SOURCE);
    const documentSnapshot = await getDoc(docRef);
    const league = documentSnapshot.data();
    setLeague(league);

    const signupsSnapshot = await getDocs(collection(db, "signups"));
    const signups = []
    signupsSnapshot.forEach((doc) => {
      const id = doc.id
      const signup = doc.data()
      signup.id = id
      signups.push(signup)
    });

    let signupsSortedNewestToOldest = signups.sort(function(s1,s2){
      let s1_date = new Date(s1.created_at.seconds * 1000 + s1.created_at.nanoseconds / 1000000)
      let s2_date = new Date(s2.created_at.seconds * 1000 + s2.created_at.nanoseconds / 1000000)
      return s2_date - s1_date;
    })
    setSignups(signupsSortedNewestToOldest)

    setDataReady(true);
  }

  const savePlayer = async (playerID) => {
    const docRef = await addDoc(collection(db, "signups"), {
      created_at: Timestamp.fromDate(new Date()),
      player_id: playerID,
      status: 'returning',
      season: UPCOMING_SEASON,
      paid: false
    });
    console.log("Document written with ID: ", docRef.id);

    window.location.href = `/signup/all/${playerID}`
  }

  function getPlayerByPlayerID (playerID) {
    return league['players'].find((player) => player.player_id == playerID)
  }

  function playerSignedUp (player_id) {
    return signups.map((signup) => signup.player_id).includes(player_id);
  }

  function displayNewPlayerForm () {
    let playerID = ''

    return (
      <div>
        <form>
          <div className="form-row">
            <div className="form-group">
              <input type="text" className="form-control" id="playerSearch"
                placeholder="Search for your name..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}>
              </input>
            </div>
          </div>
        </form>
        {searchTerm.trim() === '' ? ( <></>
        ) : (
        filteredPlayers.length > 0 ? (
          <div className='returning-player-search-results'>
            {filteredPlayers.map((player, index) => (
              <div className='returning-player-signup'>
                <p key={index}>
                  {player.first_name} {player.last_name}
                </p>
                { playerSignedUp(player.player_id) ?
                  <button className="btn btn-secondary disabled" type="button">Signed Up</button> :
                  <button className="btn btn-success" type="button" onClick={() => savePlayer(player.player_id)}>Sign Up</button>
                }
              </div>
            ))}
          </div>
        ) : (
          <p>No players found</p>
            )
        )}
      </div>
    );
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <p className="league-title-standard-secondary text-align-center">Returning Player</p>
            <a className="league-type" style={{textTransform: 'math-auto'}} href="/signup/new">New to the league? Click here to sign up as a new player</a>
            <div className="league-table-container schedule">
              { displayNewPlayerForm() }
            </div>
          </div>

        </div>

        <Footer/>
      </div>
    );
  }
}

export default ReturningPlayer;
