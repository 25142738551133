import React, { Component, useRef, useState, useEffect } from 'react';
import {Link, useParams } from "react-router-dom";
import NavBarMini from './NavBarMini.js';
import Footer from './Footer.js';
import LoadingAnimation from './LoadingAnimation.js';
import * as utils from '../common/Utils.js'

import { collection, query, where, getDoc, getDocs, setDoc, updateDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../firebase.js';

const DATABASE_SOURCE = utils.getDatabaseSource();

const PrayerIntentions = () => {
  const { prayerID } = useParams();
  const [prayerIntentions, setPrayerIntentions] = useState([]);
  const [numberOfPrayers, setNumberOfPrayers] = useState(0);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    getPrayerIntentions();
  }, [])

  const getPrayerIntentions = async () => {
    const prayerIntentionsSnapshot = await getDocs(collection(db, "prayer_intentions"));
    const prayers = []
    prayerIntentionsSnapshot.forEach((doc) => {
      const id = doc.id
      const prayer = doc.data()
      prayer.id = id
      prayers.push(prayer)
    });

    let prayersSortedNewestToOldest = prayers.sort(function(p1,p2){
      let p1_date = new Date(p1.created_at.seconds * 1000 + p1.created_at.nanoseconds / 1000000)
      let p2_date = new Date(p2.created_at.seconds * 1000 + p2.created_at.nanoseconds / 1000000)
      return p2_date - p1_date;
    })

    setNumberOfPrayers(prayers.filter((prayer) => prayer.status == 'new').length)
    setPrayerIntentions(prayersSortedNewestToOldest);
    setDataReady(true);
  }

  function displayPrayer (prayer_id) {
    const prayer = prayerIntentions.find((prayer) => prayer.id == prayer_id)

    let name = prayer.name
    if (name == '') {
      name = 'Anonymous'
    }

    const message = prayer.message
    const created_at = new Date(
      prayer.created_at.seconds * 1000 + prayer.created_at.nanoseconds / 1000000,
    );
    const month = created_at.toLocaleString('default', { month: 'short' });
    const day = created_at.toLocaleString('default', { day: 'numeric' });
    const year = created_at.toLocaleString('default', { year: 'numeric' });
    const time = created_at.toLocaleTimeString();

    const createdDate = `${month} ${day}, ${year} ${time}`; // e.g. Jan. 16, 2023

    let classes = []
    if (prayer.status == 'prayed') {
      classes.push('prayed')
    }

    return (
      <div id={ 'prayer-' + prayer_id } className={ prayerID ? '' : "league-prayer-intention " + classes.join(' ') } key={prayer_id} onClick={() => { togglePrayed(prayer_id) }}>
        <p>Name: { name }</p>
        <p>Submitted: { createdDate }</p>
        <p>Prayer: { message }</p>
      </div>
    )
  }

  async function togglePrayed (prayer_id) {
    const prayer = prayerIntentions.find((prayer) => prayer.id == prayer_id)
    let prayerElement = document.getElementById(`prayer-${prayer_id}`)

    if (prayerElement.classList.contains('prayed')) {
      prayerElement.classList.remove('prayed');
      setNumberOfPrayers(numberOfPrayers + 1);

      const prayerRef = doc(db, "prayer_intentions", prayer_id);
      await setDoc(prayerRef, { status: 'new' }, { merge: true });
    } else {
      prayerElement.classList.add('prayed');
      setNumberOfPrayers(numberOfPrayers - 1);

      const prayerRef = doc(db, "prayer_intentions", prayer_id);
      await setDoc(prayerRef, { status: 'prayed' }, { merge: true });
    }
  }

  function displayAllPrayers () {
    let newPrayers = prayerIntentions.filter((prayer) => prayer.status == 'new')
    let prayedPrayers = prayerIntentions.filter((prayer) => prayer.status == 'prayed')

    return (
      <>
        <div className="league-prayer-intentions">
          { newPrayers.map((prayer) => displayPrayer(prayer.id)) }
        </div>
        <div className="league-prayer-intentions">
          { prayedPrayers.length > 0 ? <h4 className='text-align-center'>Prayed ({ prayedPrayers.length })</h4> : '' }
          { prayedPrayers.map((prayer) => displayPrayer(prayer.id)) }
        </div>
      </>
    )
  }

  function redirectToPrayerIntentionsPage () {
    window.location.href = `/prayer-intentions/new`
  }

  if (dataReady == false) {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <LoadingAnimation />
      </div>
    )
  }
  else {
    return (
      <div className="Schedule">
        <NavBarMini alwaysShown={true} />
        <div className="league-content">

          <div className="league-body">
            <div className="dropdown league-dropdown">
            </div>
            <div className="league-table-container schedule">
              <h4 className='text-align-center'>Prayer Intention{ prayerID ? '' : `s (${numberOfPrayers} new)` }</h4>
              {
                prayerID ?
                <>
                  { displayPrayer(prayerID) }
                  <button className="btn btn-dark" type="button" onClick={redirectToPrayerIntentionsPage}>Write Another Prayer Intention</button>
                </>
                :
                displayAllPrayers()
              }
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default PrayerIntentions;
